import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    if (!this.isFullScreen()) {
      this.askForFullScreen()
    } else {
      $('#fullScreenModal').dialog({ autoOpen: false })
    }
  }

  isFullScreen () {
    return !!(
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    )
  }

  browserSupportsFullScreen () {
    return (
      document.fullscreenEnabled ||
      document.webkitFullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.msFullscreenEnabled
    )
  }

  askForFullScreen () {
    $('#fullScreenModal').dialog({
      autoOpen: true, // Open the dialog box automatically on page load
      modal: true, // Make the dialog box modal
      width: 300
    })
  }

  goFullScreen () {
    // Check if the browser supports the Fullscreen API
    if (this.browserSupportsFullScreen()) {
      // Get the element to be displayed in full screen mode
      const element = document.documentElement
      // Request full screen mode
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen()
      }
    } else {
      console.log('Fullscreen mode is not supported.')
    }

    $('#fullScreenModal').dialog('close')
  }

  doNotGoFullScreen () {
    $('#fullScreenModal').dialog('close')
  }
}
